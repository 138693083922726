import getProductView   from "@frontend/services/queries/getProductView.graphql"

// Product ACTION
export const getProductDataById = ({client},variables) => {
    return async (dispatch) => {
      let product = await client.query(
        {
          query:getProductView,
          variables
        }
      ).then(({data})=>{
        return data.productView;
      });
      await dispatch(setActualProduct(product));
      return product;
    }
}
export const setActualProduct = (product) =>{
  return {
    type: "SET_PRODUCT",
    product
  }
}
