import createAccountMutation    from '@frontend/services/mutations/createAccount.graphql';
import {
  signIn,
  getUserData,
  signOut,
  updateUser,
  setUser,
  deleteUser,
  forgottenPassword,
  resetPassword
}                               from '@themes/magento/frontend/actions/user';

export const createAccount = ({client},variables) =>{
  return async (dispatch) => {
    const response = await client.mutate({
      mutation: createAccountMutation,
      variables
    })
    .then(async ({ data:createAccount })=>{
      const response = createAccount.createAccount;
      if (response.error){
        return ({error:response.error});
      }else{
        return true;
      }

    });
    (window["rrApiOnReady"] = window["rrApiOnReady"] || []).push(function () { rrApi.setEmail(variables.customer.email, {}); });
    return response;
  }
}

export {
  signIn,
  getUserData,
  signOut,
  updateUser,
  setUser,
  deleteUser,
  forgottenPassword,
  resetPassword
}