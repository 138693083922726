import getCategoryView      from "@frontend/services/queries/getCategoryView.graphql";
import getCategoryList      from '@frontend/services/queries/getCategoryList.graphql';

export const getCategoryDataById = ({client},variables,prevCategory=null) => {
    return async (dispatch) => {
      let category = await client.query(
          {
            query:getCategoryView,
            variables,
            fetchPolicy: 'no-cache'
          }
        ).then(({data})=>{
          if(data.categoryView && data.categoryView.category_id){
            return data.categoryView;
          }
          else{
            return null;
          }
      });
      if(category){
        if (!category.items || !category.items.length){
          category.hasMoreProducts = false;
        }else{
          category.hasMoreProducts = true;
        }
        if(variables.actualPage>1 && prevCategory && prevCategory.items && (category.items!= null && category.items.length)){
          category.items = prevCategory.items.concat(category.items);
          category.hasMoreProducts = true;
        }
        category.actualPage =  variables.actualPage;
        await dispatch(setActualCategory(category));
        return category;
      }else if(prevCategory){
        let categoryUpdated = {...prevCategory}
        if(variables.actualPage==1 && variables.selectedFilters && variables.selectedFilters.length){
          categoryUpdated.items = [];
        }
        categoryUpdated.hasMoreProducts = false;
        await dispatch(setActualCategory(categoryUpdated));
        return categoryUpdated;
      }

    }
}
// CATEGORY ACTION
export const getCategoryTree = ({client},variables) => {
    return async (dispatch) => {
      await client.query(
        {
          query:getCategoryList,
          variables
        }
      ).then(({data})=>{
        let { categoryList } = data;
        dispatch(setCategoryTree(categoryList));
      });
    }
}
export const setCategoryTree = (categoryTree) =>{
  return {
    type: "SET_CATEGORY_TREE",
    categoryTree
  }
}
export const setActualCategory = (category) =>{
  return {
    type: "SET_CATEGORY",
    category
  }
}
