
export const setActiveInfoBlock = (activeInfoBlock) => {
  return {
    type: "SET_ACTIVE_INFO_BLOCK",
    activeInfoBlock
  }
}

export const setInitialActiveInfoBlock = (activeInfoBlock) => {
  return {
    type: "SET_INITIAL_ACTIVE_INFO_BLOCK",
    activeInfoBlock
  }
}
