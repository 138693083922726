import mutationSignIn           from "@frontend/services/mutations/signIn.graphql"
import queryGetUserData         from "@frontend/services/queries/getUserData.graphql"
import forgottenPasswordQuery   from "@frontend/services/queries/forgottenPassword.graphql";
import resetPasswordQuery       from "@frontend/services/queries/resetPassword.graphql";
import setGuestCartToCustomer   from "@frontend/services/mutations/setGuestCartToCustomer.graphql"
import updateCustomerData       from '@frontend/services/mutations/updateCustomerData.graphql'
import createAccountMutation    from '@frontend/services/mutations/createAccount.graphql'
import { ERROR,SUCCESS }        from '@frontend/constants';
import { addFlashMessage }      from '@frontend/actions/flash_messages';
import { setCart,
          getCartData,
          deleteCart }          from '@frontend/actions/cart';
import _has                     from 'lodash/has';
import _get                     from 'lodash/get';
import { setCookie,
  destroyCookie,
  parseCookies
}                               from 'nookies';

//USER ACTIONS
export const signIn = ({client},{email,password,storeConfiguration,minicart}, callback) => {
  return async (dispatch) => {
    // LOGIN user
    let storeCode= storeConfiguration.storeCode
    let user     = (await client.mutate({
      mutation:  mutationSignIn,
      variables: { email, password, storeCode }
    })).data.signIn;

    if(user.id){
      // set cookie
      setCookie({},`token-${_get(user, 'store_id')}`,user.token, {
        maxAge: storeConfiguration.tokenLifetime ? (storeConfiguration.tokenLifetime * 60 * 60) : 60 * 60,
        path: '/',
      });
      dispatch(setUser(user));
    }else{
      // login error
      let action = addFlashMessage({message:{ type: ERROR, text: user.error}})
      return dispatch(action);
    }
    // update guest cart
    let cookies  = parseCookies();
    let cart     = await client.mutate({
      mutation: setGuestCartToCustomer,
      variables: {
        storeId:(storeConfiguration.storeId) ? parseInt(storeConfiguration.storeId) : 0,
        storeCode:(storeConfiguration.storeCode) ? storeConfiguration.storeCode : "default",
        quoteId: _has(minicart,'cart.quote_id') ? minicart.cart.quote_id : '',
        customerToken: (user.token) ? user.token : '',
        customerId: (user.id) ? user.id : null,
        customerGroup: (user.group_id) ? user.group_id : null,
        enableCrossSellProducts: storeConfiguration. enableMinicartCrossSell
      }
    }).then(({ data }) => {
      return data.setGuestCartToCustomer;
    });;
    dispatch(setCart(cart));
    // callback if success
    if(callback){
      callback(user);
    }
    return ;
  }
}
export const createAccount = ({client},variables) =>{
  return async (dispatch) => {
    const response = await client.mutate({
      mutation: createAccountMutation,
      variables
    })
    .then(async ({ data:createAccount })=>{
      const response = createAccount.createAccount;
      if (response.error){
        return ({error:response.error});
      }else{
        return true;
      }

    });
    return response;
  }
}
export const getUserData = ({client},{token,storeId}) => {
  return async (dispatch) => {
    let user     = (await client.query({
      query:  queryGetUserData,
      variables: { storeId, token }
    })).data.userData;
    user = { ...user,token };
    dispatch(setUser(user));
    await dispatch(getCartData({client},{ quoteId:"",storeId,customerId:user.id}));
  }
}

export const signOut = (storeConfig) => {
  return async (dispatch) => {
    setCookie({},`token-${_get(storeConfig, 'storeId')}`,"",{
      maxAge: -1,
      path: '/'
    });
    setCookie({},`cart-${_get(storeConfig, 'storeId')}`,"",{
      maxAge: -1,
      path: '/'
    });
    dispatch(deleteCart());
    return dispatch(setUser({id:null,token:null,isSignedIn:false}));
  }
  
}
export const updateUser = ({client},{token,customer}) => {
  return async (dispatch) => {
    let newUserData = customer;
    let variables = {token,customer};
    await client.mutate({
      mutation:updateCustomerData,
      variables
    }).then(({data:updateCustomerData})=>{

      newUserData = updateCustomerData.updateCustomerData;
      newUserData = {...newUserData,token };
    });
    dispatch(setUser(newUserData));
    return newUserData;
  }
}
export const setUser = (user) => {
    return {
      type: "SET_USER",
      user
    }
  }
export const deleteUser = () => {
    return {
        type: "DELETE_USER"
    }
}

export const forgottenPassword = ({client},{email,websiteId}) => {
  return async (dispatch) => {
    const response = (
      await client.query({
          query:  forgottenPasswordQuery,
          variables: { email: email, websiteId: websiteId }
        })
      ).data.forgottenPassword;

    return response;
  }
}

export const resetPassword = ({client},{newPassword,resetToken}) => {
  return async (dispatch) => {
    const response = (
      await client.query({
          query:  resetPasswordQuery,
          variables: {newPassword,resetToken}
        })
      ).data.resetPassword;

    return response;
  }
}
