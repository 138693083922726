import getRetailProductInfoQuery from "@frontend/services/queries/getRetailProductInfo.graphql"

export const getRetailProductInfo = ( client , variables) => {
    return async (dispatch) => {
        let retailProductInfo = await client.query({
            query: getRetailProductInfoQuery,
            variables
        })
            .then(({ data }) => {
                return (data.retailProductInfo);
            });

        dispatch(setRetailProductInfo(retailProductInfo));
        return retailProductInfo;
    }
}
export const setRetailProductInfo = (retailProductInfo) => {
    return {
        type: "SET_RETAIL_PRODUCT_INFO",
        retailProductInfo
    }
}
