import getCMSPageQuery   from '@frontend/services/queries/getCMSPage.graphql';

export const getCMSPage = ({client}, variables) => {
  return async (dispatch) => {
    const cmsPage = await client.query({
      query: getCMSPageQuery,
      variables
    }).then(({ data }) => {
      let { cms_page } = data;
      return cms_page;
    });
    dispatch(setCMSPage(cmsPage));
    return cmsPage;
  }
}
export const setCMSPage = (cmsPage) => {
  return {
    type: "SET_CMSPAGE",
    cmsPage
  }
}
