import getMapStoresQuery   from "@frontend/services/queries/getMapStores.graphql"

export const getMapStores = ({client}) =>{
  return async (dispatch) => {
    let mapStores = await client.query({
      query: getMapStoresQuery
    })
    .then(({ data })=>{
      return (data);
    });
    dispatch(setMapStores(mapStores));
    return mapStores;
  }
}
export const setMapStores = (mapStores) => {
  return {
    type: "SET_MAP_STORES",
    mapStores
  }
}
