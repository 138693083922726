export const MESSAGES_LIFETIME  = 5000;
export const ERROR              = "error";
export const SUCCESS            = "success";
export const SEMI_SUCCESS       = "semi-success";

export const GALLERY_IMAGE_TYPES = {
  category_view_list: 'category_page_list',
  product_view: 'product_page_image_medium',
  mini_cart: 'mini_cart_product_thumbnail'
}

export const FILTER_TYPES =
{
    select:0,
    multiselect:1,
    price_range : 2,
    category:'category'
};

export const FILTER_STYLES =
{
    box:0,
    background:1,
    link:2,
    array:['box','background','link']
};

export const FRONT_SWATCH_TYPES = {
  label : 0,
  background: 1,
  select : 2,
  visual: 3,
  array: ['label','background','select','visual']
};

// log interceptor
export const DEBUG_MODE = true;

// Date format
export const DATE_FORMAT           = "YYYY-MM-DD";
export const ES_DATE_FORMAT        = "YYYY-MM-DD";
export const EN_DATE_FORMAT        = "YYYY-MM-DD";
export const FR_DATE_FORMAT        = "YYYY-MM-DD";  // dates will work if format changes. i.e. "DD/MM/YYYY"
export const LOCALE_DATE_FORMAT    = {
  en: EN_DATE_FORMAT,
  fr: FR_DATE_FORMAT,
  es: ES_DATE_FORMAT
}
export const FIRST_DAY_WEEK_NUMBER = 1;             // Monday
// header label will modify scrolling data
export const HEADER_BANNER_ENABLED     = false;
export const HEADER_BANNER_HEIGHT      = 28;

export const DEFAULT_HEADER_HEIGHT     = 144;
export const HEADER_HEIGHT             = HEADER_BANNER_ENABLED ? HEADER_BANNER_HEIGHT + DEFAULT_HEADER_HEIGHT : DEFAULT_HEADER_HEIGHT;

export const DEFAULT_DT_SCROLL_OFFSET  = -198;
export const DEFAULT_MB_SCROLL_OFFSET  = -110;
export const DT_SCROLL_OFFSET          = HEADER_BANNER_ENABLED ? DEFAULT_DT_SCROLL_OFFSET - HEADER_BANNER_HEIGHT : DEFAULT_DT_SCROLL_OFFSET;
export const MB_SCROLL_OFFSET          = HEADER_BANNER_ENABLED ? DEFAULT_MB_SCROLL_OFFSET - HEADER_BANNER_HEIGHT : DEFAULT_MB_SCROLL_OFFSET;

// media query max width breakpoint
export const MEDIA_QUERY_MAX_BREAKPOINT = '(max-width: 768px)';
