import getStoreCountriesQuery      from '@frontend/services/queries/getStoreCountries.graphql';

export const setStoreConfig = ( config ) =>{
  return {
    type: "SET_STORE_CONFIG",
    config
  }
}

export const setStoreCountries = ( countries ) =>{
  return {
    type: "SET_STORE_COUNTRIES",
    countries
  }
}
export const getStoreCountries = ({client},variables) => {
  return async (dispatch) => {
    let countries = await client.query({
            query:getStoreCountriesQuery,
            variables
          }).then(({data:{ storeCountries }})=>{
            return storeCountries;
        });
    return dispatch(setStoreCountries(countries));
  }
}
