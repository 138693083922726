import subscribeNewsletterQuery  from '@frontend/services/mutations/subscribeNewsletter.graphql';

export const subscribeNewsletter = ({client},variables) => {
  return async (dispatch) => {
    await client.mutate(
      {
        mutation:subscribeNewsletterQuery,
        variables
      }
    ).then(({data})=>{
      let subscribeResult = '';
      if(data&&data.newsletter){
        dispatch(setSubscriptionResult(data.newsletter));
        delayDeleteSubscriptionResult(dispatch);
      }
    });
  }

}
export const setSubscriptionResult = (newsletterResult)=>{
  return {
    type: "SET_SUBSCRIPTION_RESULT",
    newsletterResult
  }
}

export const deleteSubscription = () => {
  return {
    type: "DELETE_SUBSCRIPTION_RESULT"
  }
}
export function delayDeleteSubscriptionResult (dispatch){
  setTimeout(()=>{
    dispatch(deleteSubscription());
  }, 100000);
}
