import getCanRMAQuery   from "@frontend/services/queries/getCanRMA.graphql"
import rmaForm          from '@frontend/services/mutations/sendRMAForm.graphql'

export const sendRMAForm = (apollo, variables) => {
    return async (dispatch) => {
        let rmaFormResponse = await apollo.mutate(
            {
                mutation: rmaForm,
                variables
            }
        ).then(({ data }) => {
            if (data) return data.rma_response;
            return data;
        });
        return rmaFormResponse;
    }
}


export const getCanRMA = ({ client }, variables) => {
    return async (dispatch) => {

        let data = await client.query({
            query: getCanRMAQuery,
            variables
        })
            .then(({ data }) => {
                return (data);
            });

        dispatch(setCanRMA(data));
        return data;
    }
}
export const setCanRMA = (data) => {
    return {
        type: "SET_CAN_RMA",
        data
    }
}
