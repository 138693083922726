import searchProduct          from "@frontend/services/queries/searchProduct.graphql";

// Search actions
export const getSearchData = ({client},variables,prevResults=null) => {
    return async (dispatch) => {
      let searchResults = {
        items:            [],
        num:              0,
        hasMoreProducts : false,
        showSearchBar   : false
      };
      const { isSearchResultPage=false,...params } = variables;

      searchResults.items = await client.query(
        {
          query:        searchProduct,
          variables:    params,
          fetchPolicy:  'no-cache'
        }
      ).then(({data})=>{
        let searchResults = data.searchProduct;
        return searchResults;
      });
      searchResults['num'] = (searchResults && searchResults.items) ? searchResults.items.length : 0;

      if(searchResults){
        searchResults.showSearchBar     = true;
        if (!searchResults.items || !searchResults.items.length){
          searchResults.hasMoreProducts = false;
        }else{
          searchResults.hasMoreProducts = true;
        }
        if(prevResults && prevResults.items && searchResults.items!= null){
          searchResults.items = prevResults.items.concat(searchResults.items);
        }
        searchResults.actualPage =  variables.actualPage;
        (isSearchResultPage) ?
          await dispatch(setSearchResultsPageData(searchResults))
        :
          await dispatch(setSearchResults(searchResults));
      }else if(prevResults){
        prevResults.hasMoreProducts = false;
        (isSearchResultPage) ?
          await dispatch(setSearchResults(prevResults))
        :
          await dispatch(setSearchResultsPageData(prevResults));
      }

    }
}

export const setSearchResults = (searchResults) =>{
  return {
    type: "SET_SEARCH_RESULTS",
    searchResults
  }
}

export const deleteSearchResults = () =>{
  return {
    type: "DELETE_SEARCH_RESULTS"
  }
}

export const setSearchResultsPageData = (searchResultsPageData) =>{
  return {
    type: "SET_SEARCH_RESULTS_PAGE_DATA",
    searchResultsPageData
  }
}

export const closeSearchBar= ()=>{
  return {
    type: "CLOSE_SEARCH_BAR"
  }
}
export const openSearchBar= ()=>{
  return {
    type: "OPEN_SEARCH_BAR"
  }
}

export const setSearchValue= (searchValue)=>{
  return {
    type: "SET_SEARCH_VALUE",
    searchValue
  }
}

export const deleteSearchValue = () => {
  return {
    type: "DELETE_SEARCH_VALUE"
  }
}
