
export const setActiveTheme = (activeTheme) => {
  return {
    type: "SET_ACTIVE_THEME",
    activeTheme
  }
}

export const setActiveSection = (activeSection) => {
  return {
    type: "SET_ACTIVE_SECTION",
    activeSection
  }
}


export const setMenuHidden = (hideMegaMenu) => {
  return {
    type: "SET_MENU_HIDDEN",
    hideMegaMenu
  }
}


export const setMenuBackground = (menuBackground) => {
  return {
    type: "SET_MENU_BACKGROUND",
    menuBackground
  }
}

export const setSelectedLabel = (selectedLabel) => {
  return {
    type: "SET_SELECTED_LABEL",
    selectedLabel
  }
}

