import productsByIds   from "@frontend/services/queries/productsByIds.graphql"

// Product Slider By Ids
export const getProductsDataByIds = ({client},variables) => {
    return async (dispatch) => {
      let products = await client.query(
        {
          query:productsByIds,
          variables
        }
      ).then(({data})=>{
        return data.productsByIds;
      });
      return products;
    }
}
