import getOrderDetail       from '@frontend/services/queries/getOrderDetail.graphql';
import getUserOrdersQuery   from '@frontend/services/queries/getUserOrders.graphql';

export const MAX_ORDERS_PAGE = 50;

export const getUserOrderDetail = ({client},variables) => {
  return async (dispatch) => {
    let orderDetail = client.query(
      {
        query:getOrderDetail,
        variables
      }
    ).then(({data})=>{
      let { orderDetail } = data;
      dispatch(setViewingOrder(orderDetail));
      return orderDetail;
    });

    return orderDetail;
  }

}
export const getUserOrders = ({client},variables) => {

  return async (dispatch) => {
    client.query(
      {
        query:getUserOrdersQuery,
        variables
      }
    ).then(({data})=>{
      let { userOrders } = data;
      let npages = Math.ceil(userOrders.total/MAX_ORDERS_PAGE);
      userOrders = {...userOrders,npages};
      return dispatch(setUserOrders(userOrders));
    });
  }

}
export const setUserOrders = ( userOrders ) =>{
  return {
    type: "SET_USER_ORDERS",
    userOrders
  }
}
export const setViewingOrder = ( orderDetail ) =>{
  return {
    type: "SET_VIEWING_ORDER",
    orderDetail
  }
}
