import queryGetCartData                           from '@frontend/services/queries/getCartData.graphql'
import addItemMutation                            from '@frontend/services/mutations/addCartItem.graphql'
import deleteItemMutation                         from '@frontend/services/mutations/deleteCartItem.graphql'
import changeItemMutation                         from '@frontend/services/mutations/changeCartItem.graphql'
import addCartCouponMutation                      from '@frontend/services/mutations/addCartCoupon.graphql';
import deleteCartCoupon                           from '@frontend/services/mutations/deleteCartCoupon.graphql';
import validateCartItemsQuery                     from "@frontend/services/queries/validateCartItems.graphql";
import { addFlashMessage }                        from '@frontend/actions';
import { ERROR }                                  from '@frontend/constants';
import { setCookie,destroyCookie,parseCookies }   from 'nookies'


export const getCartData = ({client,scriptManager},{quoteId,storeId,customerId,customerGroup,enableCrossSellProducts}) => {
  return async (dispatch) => {
    let cart     = (await client.query(
      {
        query:queryGetCartData,
        variables:{
          quoteId,
          storeId,
          customerId,
          customerGroup,
          enableCrossSellProducts
        },
        fetchPolicy: 'no-cache'
      })).data.getCartData;
    return dispatch(setCart(cart));
  }
}

export const addItemToCart = ({client,scriptManager},variables) =>{
  return async (dispatch) => {
    const { storeId } = variables;
    client.mutate({
        mutation: addItemMutation,
        variables
    }).then(({data})=>{
      if (data.addItemToCart.error && data.addItemToCart.error.length){
        let message = {
          type: ERROR,
          text: data.addItemToCart.error
        };
        dispatch(addFlashMessage({message}));
      }else{
        dispatch(setCart(data.addItemToCart));
        dispatch(openCart());
        setCookie({},`cart-${storeId}`,data.addItemToCart.quote_id, {
          maxAge: 60 * 60,
          path: '/',
        });
        scriptManager.dispatch("AddedToCart",{product: variables, cart: data.addItemToCart})
      }
    });
  }
}
export const changeCartItem = ({client,scriptManager},variables) =>{
  return async (dispatch) => {
    client.mutate({
        mutation: changeItemMutation,
        variables
    }).then(({data})=>{
      if (data.changeItemFromCart.error && data.changeItemFromCart.error.length){
        let message = {
          type: ERROR,
          text: data.changeItemFromCart.error
        };
        dispatch(addFlashMessage({message}));
      }else{
        dispatch(setCart(data.changeItemFromCart));
        scriptManager.dispatch("ChangedCartItem",{product: variables, cart: data.changeItemFromCart})
      }
    });
  }
}
export const deleteItemFromCart = ({client,scriptManager},variables) =>{
  return async (dispatch) => {
    client.mutate({
        mutation: deleteItemMutation,
        variables
    }).then(({data})=>{
      if (data.deleteItemFromCart.error && data.deleteItemFromCart.error.length){
        let message = {
          type: ERROR,
          text: data.deleteItemFromCart.error
        };
        dispatch(addFlashMessage({message}));
      }else{
        dispatch(setCart(data.deleteItemFromCart));
        scriptManager.dispatch("DeletedCartItem",{product: variables, cart: data.deleteItemFromCart})
      }
    });
  }
}

export const addCartCoupon = ({client,i18n},variables) =>{
  return async (dispatch) => {
    client.mutate({
        mutation: addCartCouponMutation,
        variables
    }).then(({data})=>{
      if (data.addCartCoupon && data.addCartCoupon.quote_id){
        dispatch(setCart(data.addCartCoupon));
      }else{
        let message = {
          type: ERROR,
          text: i18n.t('coupons.addCouponError')
        };
        dispatch(addFlashMessage({message}));
        dispatch(closeCart());
      }
    });
  }
}
export const cancelCartCoupon = ({client,scriptManager},variables) =>{
  return async (dispatch) => {
    await client.mutate({
        mutation: deleteCartCoupon,
        variables
    }).then(({data})=>{
      dispatch(setCart(data.deleteCartCoupon));
    });
  }
}
export const validateCartItems = ({ client, router }, variables) => {
  return async (dispatch) => {
    client.query({
      query: validateCartItemsQuery,
      variables,
      fetchPolicy: 'no-cache'
    }).then(({ data }) => {
      if (data.validateCartItems.length) {
        dispatch(setCartItemsErrors(data.validateCartItems));
      } else {
        const { customerToken, quoteId, storeConfiguration } = variables;
        if (customerToken) {
            return router.goToExternalUrl(storeConfiguration.checkoutURL + "?token=" + customerToken);
        } else {
            return router.goToExternalUrl(storeConfiguration.checkoutURL + "?masked=" + quoteId + "&store=" + storeConfiguration.storeId);
        }
      }
    });
  }
}
export const setCart = (cart) => {
  return {
    type: "SET_CART",
    cart
  }
}

export const deleteCart = () => {
  return {
    type: "DELETE_CART"
  }
}

export const openCart = () => {
  return {
    type: "OPEN_CART"
  }
}

export const closeCart = () => {
  return {
    type: "CLOSE_CART"
  }
}

export const setCartItemsErrors = (cartItemsErrors) => {
  return {
    type: "CART_ITEMS_ERRORS",
    cartItemsErrors
  }
}
