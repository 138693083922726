import { addFlashMessage }    from '@frontend/actions';
import { SUCCESS, ERROR }     from '@frontend/constants';

import addItemMutation      from '@frontend/services/mutations/addItemToWishlist.graphql';
import deleteItemMutation   from '@frontend/services/mutations/deleteItemFromWishlist.graphql';
import getCustomerWishlist  from '@frontend/services/queries/getCustomerWishlist.graphql';

export const addItemToWishlist = ({client,scriptManager},i18n,variables) => {
  return async (dispatch) => {

    return await client.mutate({
        mutation: addItemMutation,
        variables
    }).then(({data})=>{
      let message = {
        type: (data.addItemToWishlist.ok) ? SUCCESS : ERROR,
        text: i18n.t(`addToWishlist.${(data.addItemToWishlist.ok) ? 'success':'error'}`)
      };
      if(data.addItemToWishlist.ok){
        dispatch(addItem(variables.productId));
        scriptManager.dispatch("AddedToWishlist",{variables, wishlist: data.addItemToWishlist});
        return data.addItemToWishlist.ok;
      }
      // dispatch(addFlashMessage({message}));
    });
  }
}
export const deleteItemFromWishlist = ({client},i18n,{productId,customerId})  => {
  return async (dispatch) => {
    return await client.mutate({
        mutation: deleteItemMutation,
        variables: {
          customerId,
          productId
        }
    }).then(({data})=>{
      let message = {
        type: (data.deleteItemFromWishlist.ok) ? SUCCESS : ERROR,
        text: i18n.t(`deleteFromWishlist.${(data.deleteItemFromWishlist.ok) ? 'success':'error'}`)
      };
      if(data.deleteItemFromWishlist.ok){
        dispatch(deleteItem(productId));
        return data.deleteItemFromWishlist.ok;
      }
      // dispatch(addFlashMessage({message}));
    });
  }
}
export const getWishlistProductList = ({client},variables) =>{
  return async (dispatch) => {
    await client.query(
      {
        query:getCustomerWishlist,
        variables,
        fetchPolicy: 'no-cache'
      }
    ).then(data=>{
      const wishlist = data.data.getCustomerWishlist;
      dispatch(setWishlistProductList(wishlist));
    });
  }
}
export const setWishlistProductList = (wishlistProductList) => {
  return {
    type: "SET_WISHLIST_PRODUCT_LIST",
    wishlistProductList
  }
}
export const addItem = (productId) => {
  return {
    type: "ADD_ITEM_WISHLIST",
    productId
  }
}
export const deleteItem = (productId) => {
  return {
    type: "DELETE_ITEM_WISHLIST",
    productId
  }
}
