export const addFlashMessage = (message) =>{
    return{
      type: "FLASH_MESSAGES_ADD",
      message
    }
  }
  export const deleteFlashMessage = (id) =>{
    return {
      type: "FLASH_MESSAGES_DELETE",
      id
    }
  }
  
  export const getMessages = () => {
    return {
      type: "FLASH_MESSAGES_LIST"
    }
  }
  