export * from "@themes/store/frontend/constants";

export const MESSAGES_LIFETIME  = 5000;
export const ERROR              = "error";
export const SUCCESS            = "success";
export const SEMI_SUCCESS       = "semi-success";

export const FILTER_TYPES =
{
    select:0,
    multiselect:1,
    price_range : 2,
    category:'category'
};
export const ADDRESS_BILLING_TYPE   = 'billing';
export const ADDRESS_SHIPPING_TYPE  = 'shipping';
export const FILTER_STYLES =
{
    box:0,
    background:1,
    link:2,
    array:['box','background','link']
};

export const FRONT_SWATCH_TYPES = {
  label : 0,
  background: 1,
  select : 2,
  visual: 3,
  array: ['label','background','select','visual']
};

// log interceptor
export const DEBUG_MODE = true;

// Date format
export const DATE_FORMAT           = "MM/DD/YY";
export const EN_DATE_FORMAT        = "MM/DD/YY";
export const ES_DATE_FORMAT        = "MM/DD/YY";
export const FR_DATE_FORMAT        = "MM/DD/YY";  // dates will work if format changes. i.e. "DD/MM/YYYY"
export const LOCALE_DATE_FORMAT    = {
  en: EN_DATE_FORMAT,
  fr: FR_DATE_FORMAT,
  es: ES_DATE_FORMAT
}
export const FIRST_DAY_WEEK_NUMBER = 1;             // Monday

// header label will modify scrolling data
export const HEADER_BANNER_ENABLED     = true;
export const HEADER_BANNER_HEIGHT      = 28;

export const DEFAULT_HEADER_HEIGHT     = 144;
export const HEADER_HEIGHT             = HEADER_BANNER_ENABLED ? HEADER_BANNER_HEIGHT + DEFAULT_HEADER_HEIGHT : DEFAULT_HEADER_HEIGHT;

export const DEFAULT_DT_SCROLL_OFFSET  = -198;
export const DEFAULT_MB_SCROLL_OFFSET  = -110;
export const DT_SCROLL_OFFSET          = HEADER_BANNER_ENABLED ? DEFAULT_DT_SCROLL_OFFSET - HEADER_BANNER_HEIGHT : DEFAULT_DT_SCROLL_OFFSET;
export const MB_SCROLL_OFFSET          = HEADER_BANNER_ENABLED ? DEFAULT_MB_SCROLL_OFFSET - HEADER_BANNER_HEIGHT : DEFAULT_MB_SCROLL_OFFSET;


export const DEFAULT_CATEGORY_ID        = 2;
export const FRAGRANCE_CATEGORY_ID      = 36;
export const SUN_PROTECTION_CATEGORY_ID = 89;
export const BLACK_FRIDAY_CATEGORY_ID   = 142;
export const XMAS_PRESENTS_CATEGORY_ID  = 143;
export const HERITAGE_CATEGORY_ID       = 172;
export const HOMECOMING_CATEGORY_ID     = 183;
export const CAPILAR_CATEGORY_ID        = 115;
export const GIFT_IDEAS_CATEGORY_ID     = 186;

export const UNITS_LEFT_STOCK_MAXIMUM = 10;

export const PRIVACY_POLICY = {
  defaultId: 1,
  byStore: {
    1:{
      cmsId: 20,
      slug: '/politica-privacidad',
    },
    5:{
      cmsId: 105,
      slug: '/privacy-policy',
    }
  }
}