import getCMSBlockQuery   from '@frontend/services/queries/getCMSBlock.graphql'

export const getCMSBlock = ({client},variables) =>{
  return async (dispatch) =>{
    let cmsBlock = await client.query({
      query:getCMSBlockQuery,
      variables
    }).then(({ data })=>{
      let { cmsBlock } = data;

      return cmsBlock;
    });

    dispatch(setCMSBlock(cmsBlock));
    return cmsBlock;
  }
}

export const setCMSBlock = (cmsBlock) => {
  return {
    type: "SET_CMSBLOCK",
    cmsBlock
  }
}
