import getMegamenuQuery   from "@frontend/services/queries/getMegamenu.graphql"

export const getMegamenu = ({client},variables) =>{
  return async (dispatch) => {
    let megamenu = await client.query({
      query: getMegamenuQuery,
      variables
    })
    .then(({ data })=>{
      const { megamenu } = data;
      let finalData = [];
      const content = (megamenu) ? JSON.parse(megamenu.content):[];

      content.map((item)=>{
        let object =
        {
           "item_type":"",
           "label":"",
           "url":"",
           "category":null
        }
        object['item_type'] = item.item_type;
        object['label']= item.content.label;
        object['url'] = item.content.url;
        object['css_class'] = item.content.class;
        object['category'] = (item.item_type=='category') ? item.content.category : null;

        let filtered = (item.item_type=='category') ? 
          (megamenu.children.filter(item=>item.category_id == object['category']).length) ?
            megamenu.children.filter(item=>item.category_id == object['category'])
            :[]
          :[];
          
        object['rel'] = (item.item_type=='category') ?
          (filtered.length) ?
            filtered[0]['rel']
            :null
          :null;
        object['children'] = (item.item_type=='category') ?
          (filtered.length) ?
            filtered[0]['children']
            :[]
          :[];
        finalData.push(object);
      });

      return finalData;
    });
    dispatch(setMegamenu(megamenu));
    return megamenu;
  }
}
export const setMegamenu = (megamenu) => {
  return {
    type: "SET_MEGAMENU",
    megamenu
  }
}
