import getCategoryView    from "@frontend/services/queries/getCategoryView.graphql";
import {
  getCartData,
  addItemToCart,
  changeCartItem,
  deleteItemFromCart,
  addCartCoupon,
  cancelCartCoupon,
  validateCartItems,
  setCart,
  deleteCart,
  openCart,
  closeCart,
  setCartItemsErrors
}                         from '@themes/magento/frontend/actions/cart';

export const getCrossSellCategory = ({client},variables) => {
  return async (dispatch) => {
    let crossSellCategory = {};
    crossSellCategory = await client.query(
        {
          query:getCategoryView,
          variables,
          fetchPolicy: 'no-cache'
        }
      ).then(({data})=>{
        if(data.categoryView && data.categoryView.category_id){
          return data.categoryView;
        }else{
          return{};
        }
    });
    crossSellCategory.hasMoreProducts  = false;
    crossSellCategory.actualPage       = variables.actualPage;
    return dispatch(setCrossSellCategory(crossSellCategory));
  }
}

export const setCrossSellCategory = (crossSellCategory) =>{
  return {
    type: "SET_CROSS_SELL_CATEGORY",
    crossSellCategory
  }
}

export const setCartBanner = (banner) =>{
  return {
    type: "SET_CART_BANNER",
    banner
  }
}

export {
  getCartData,
  addItemToCart,
  changeCartItem,
  deleteItemFromCart,
  addCartCoupon,
  cancelCartCoupon,
  validateCartItems,
  setCart,
  deleteCart,
  openCart,
  closeCart,
  setCartItemsErrors
}