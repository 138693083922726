import React,{Component} from 'react';
import Context           from '@frontend/context';

class EzComponent extends Component{
  static contextType  = Context;

  constructor(props,context){
    super(props,context);

    if(context){
      const { i18n,router,scriptManager } = context;

      if(i18n){
        this.i18n = i18n;
      }

      if(router){
        this.router = router;
      }

      if (scriptManager) {
        this.scriptManager = scriptManager;
      }
    }
  }
}

export default EzComponent;
